import React from "react";
import styled from "styled-components";
import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";



const Header = props => (
  <div className="header-wrap" image={props.image}>
    <Row className="show-grid margin-left0">
      <Col className="zero-padding" xs={12}>
        <div className="header-group">
          <h1 className="header-title">{props.title}</h1>
          <div className="header-text"
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></div>
        </div>
      </Col>
    </Row>
  </div>
);

export default Header;
