import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";

const ContactPage = () => (
  <Layout>
    <Row className="show-grid">
      <Col xs={12}>
        <Header title="Richard England-Lee" />
      </Col>
    </Row>

    <Row className="show-grid pb60">
      <Col xs={12} sm={3} md={3}>
        <h3>Email</h3>
        <p>richard@england-lee.co.uk</p>
      </Col>
      <Col xs={12} sm={3} md={3}>
        <h3>Phone</h3>
        <p>+44 754 0555 629</p>
      </Col>
      <Col xs={12} sm={3} md={3}>
        <h3>Twitter</h3>
        <p>@richhawk106</p>
      </Col>
      <Col xs={12} sm={3} md={3}></Col>
    </Row>
  </Layout>
);

export default ContactPage;
